.product-configuration p{
    font-size: 16px;
    font-weight: 300;
    color: #000;
    line-height: 24px;
}

.product-configuration b{
    text-transform: capitalize;
}

.heading-size {
    font-weight: bold !important;
    font-size: 18px;
}

.detail-field-list li {
    list-style-type: square;
    display: list-item;
}

.detail-field-style li
{
    font-size: 16px;
    color: #000;
    display: list-item;
    list-style-type: unset;
}

li {
    display: list-item;
    list-style-type: unset;
}
.detail-field-style + p,
strong {
    font-weight: 800;
}

.detail-field-style ul {
    padding-left: 2rem;
    ul {
        padding-left: 3rem;
        ul {
            padding-left: 4rem;
        }
    }
}

.days-span {
    // display:table; 
    // margin:auto; 
    margin-top: 10px;
    padding: 0 10px;
    color: #fff;
    // font-size: larger;
    font-weight: 600;
    letter-spacing: 1px;
    outline: 3px double rgb(216 219 221);
    background-color:rgb(26, 165, 216);
}