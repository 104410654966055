.ribbon {
    font-size: 20px;
    position: relative;
    display: inline-block;
    // margin:5em;
    text-align: center;
    margin-top: 30px;
}
.text {
    display: inline-block;
    padding: 0.5em 1em;
    // min-width:20em;
    line-height: 1.2em;
    background: #1aa5d8;
    position: relative;
}
.ribbon:after,
.ribbon:before,
.text:before,
.text:after,
.bold:before {
    content: "";
    position: absolute;
    border-style: solid;
}
.ribbon:before {
    top: 0.3em;
    left: 0.2em;
    width: 100%;
    height: 100%;
    border: none;
    background: #ebeced;
    z-index: -2;
}
.text:before {
    bottom: 100%;
    left: 0;
    border-width: 0.5em 0.7em 0 0;
    border-color: transparent #05445e transparent transparent;
}
.text:after {
    top: 100%;
    right: 0;
    border-width: 0.5em 2em 0 0;
    border-color: #05445e transparent transparent transparent;
}
.ribbon:after,
.bold:before {
    top: 0.5em;
    right: -2em;
    border-width: 1.1em 1em 1.1em 3em;
    border-color: #127ca3 transparent #127ca3 #127ca3;
    z-index: -1;
}
.bold:before {
    border-color: #ebeced transparent #ebeced #ebeced;
    top: 0.7em;
    right: -1.3em;
}

.animate-charcter {
    text-transform: uppercase;
    background-image: linear-gradient(
        -225deg,
        #fff800 0% #ff1361 29%,
        #44107a 67%,
        #231557 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    // text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: auto;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}

@media (min-width: 360px) and (max-width: 540px) {
    .ribbon {
        font-size: 13.5px;
    }
}
